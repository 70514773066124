<template>
  <div id="biography_braveen__">
    <Navbar v-bind:isBiography="true" />
    <Background />
    <div data-aos="zoom-out" data-aos-duration="1000" class="biography_b__">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <div class="biography_profile__">
                <div class="biography_sketch1__">
                  <div class="--biography_sketch1__">
                    <div data-depth="0.2">
                      <svg
                        width="562.601"
                        height="757.062"
                        viewBox="0 0 562.601 757.062"
                      >
                        <defs>
                          <linearGradient
                            id="a"
                            x1="0.5"
                            x2="0.5"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stop-color="#ffce00" />
                            <stop offset="1" stop-color="#f75a5a" />
                          </linearGradient>
                        </defs>
                        <path
                          style=" opacity: 0.352;
                          fill: url(#a);"
                          d="M604.419,1557.3c45.058-153.166-214.73-163.554-325.579-101.868s-39.438,118.656-118.8,162.006,4.724,58.455,52.731,104.421-55.787,250.21,10.112,314.789,89.2-64.991,221.155-56.136-43.078-150.015-12.024-224.26S559.361,1710.462,604.419,1557.3Z"
                          transform="translate(90 2070.28) rotate(-161)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="biography_b">
                  <div class="--biography_b">
                    <div data-depth="0.4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="491.52"
                        viewBox="0 0 491.52 511.307"
                        style="transform: translate(50px, 8px);"
                      >
                        <g transform="translate(-1288.821 -187.592)">
                          <g transform="translate(1288.821 187.592)">
                            <path
                              style="fill: #c67a60;"
                              d="M541.412,1377.646,523.27,1384.4l18.675,64.739,156.248,24.277,8.715-90.261-46.052-13.073-101.607,4a57.633,57.633,0,0,0-17.837,3.57Z"
                              transform="translate(-366.668 -1003.828)"
                            />
                            <path
                              style="fill: #262626;"
                              d="M486.152,492.361c-22.263-41.4-36.487-59.708-43.754-94.649-4.324-6.419-6.389-13.509-7.317-21.21-3.262-27.117-5.546-54.249-3.292-81.579-1.8-21.916-12.869-16.34-14.847-38.238-2.993-33.219,51.415-69.611,71.868-96.043,116.609-47.863,142.856,35.234,178.481,83.626-2.236,18.926-6.36,37.634-9.631,56.437-.943,27.833-6.306,55.132-9.595,82.681-.649,5.453-4.319,9.385-6.315,14.141-3.765,15.637-7.054,18.474-11.672,33.857-6.928,23.14-21.111,33.776-41.695,66.63-1.685,2.993-3.891,5.6-7.135,6.7-31.2,10.558-61.618,9.972-90.752-6.874A9.754,9.754,0,0,1,486.152,492.361Z"
                              transform="translate(-292.078 -146.28)"
                            />
                            <path
                              style="fill: #00a8db;"
                              d="M358.514,1442.81c35.123,16.909,70.575,33.169,105.234,50.978,19.079,9.8,24.11,29.521,28.329,48.878.183,21.093.183,21.093-21.033,21.093q-223.881,0-447.759.266c-12.3.033-19.154-2.221-17.316-15.888,4.489-28.985,16.287-51.215,46.169-62.964,29.1-11.438,56.515-27.171,84.668-41.019,8.167,2.768,10.415,10.678,14.5,16.918,15.472,23.6,33.953,44.077,61.261,53.7,51.146,18.043,104.65,6.515,133.755-51.439C349.859,1456.271,350.817,1447.467,358.514,1442.81Z"
                              transform="translate(-3.975 -1054.792)"
                            />
                            <path
                              style="fill: #00a8db;"
                              d="M460.932,1415.763c2.328-1.6,4.72-3.115,6.973-4.815,9.179-6.922,14.126-5.985,19.48,5.806,16.038,35.374,43.347,55.306,83.471,53.962,34.644-1.161,58.173-19.177,72.52-49.979,7.892-16.933,7.643-17.058,23.972-8.437,2.095,1.1,4,2.52,5.985,3.792-10.8,37.966-31.861,67.956-71.227,78.557s-76.216,3.052-107.17-25.708c-12.674-11.779-23.487-24.911-31.514-40.261C461.327,1424.678,457.206,1420.943,460.932,1415.763Z"
                              transform="translate(-321.933 -1029.42)"
                            />
                            <path
                              style="fill: #a57367;"
                              d="M648.234,1302.68c33.163,18.525,67.358,18.372,102.241,5.653.368,7.084.8,12.788.814,21.317.054,25.438-29.311,55.737-55.964,54.468-14.165-.677-48.875-23.8-47.133-49.793C648.949,1323.07,648.174,1317.844,648.234,1302.68Z"
                              transform="translate(-454.159 -956.599)"
                            />
                            <path
                              style="fill: #a57367;"
                              d="M414.35,643c5.4,26.489,3.268,53.717,7.886,80.292,1.293,7.437,1.844,15,2.735,22.5-29.784-14.114-32.109-36.748-32.316-68.824-.093-14.159-4.507-23.044,9.93-22.781C411.786,654.348,414.195,650.694,414.35,643Z"
                              transform="translate(-274.648 -494.344)"
                            />
                            <path
                              style="fill: #a57367;"
                              d="M1168.4,759.08c6.267-32.121,4.354-65.589,15.909-96.83.668,1.26,1.03,3.193,2.053,3.657,22.248,10.068,18.082,17.394,11.187,41.129C1191.324,728.488,1187.4,747.238,1168.4,759.08Z"
                              transform="translate(-818.727 -507.833)"
                            />
                            <path
                              style="fill: #065c70;"
                              d="M1.991,1781.969c1.2,12.57,8.38,14.569,20.12,14.53,147.18-.472,294.363-.3,441.546-.3,20.91,0,20.91,0,24.442-19.689,6.09,23.427,6.09,23.421-17.358,23.421q-227.383-.018-454.762-.039C-1.765,1799.877-1.765,1799.823,1.991,1781.969Z"
                              transform="translate(0.003 -1288.624)"
                            />
                            <path
                              style="fill: #065c70;"
                              d="M450.036,1437.21c26.833,73.466,111.225,105.889,172.7,60.5,18.346-13.548,27.144-34.057,36.568-53.917,1-2.095,2.095-4.157,3.136-6.234l3.13,1.332c-13.363,37.535-33.495,69.276-75.013,79.538-39.8,9.834-76.07,2.2-107.389-25.795-16.7-14.919-28.6-33.127-39.292-52.4C445.269,1437.883,447.591,1437.422,450.036,1437.21Z"
                              transform="translate(-311.037 -1050.868)"
                            />
                            <path
                              style="fill: #c67a60;"
                              d="M671.533,624.068c-12.956,13.856-1.17-35.033-8.344-48.105-7.452-13.584-19.222-1.918-32.92,2.867a29.421,29.421,0,0,1-20.369.183c-39.088-12.869-23.807,40.225-39.052,47.884-11.914,5.985-17.936-34.036-22.927-41.111-4.788-7.628-9.023-39.75-12.177-46.226-14.6-31.16-4.3-75.279,6.285-106.521,30.843-3.035,53.286,1.879,76.851-11.63,17.861-10.256,37.269-11.519,57.619-11.639,12.408-.075,16.376,20.569,21.162,31.532,14.3,32.747,22.823,66.107,6.584,100.951C701.451,549.632,693.622,600.443,671.533,624.068Z"
                              transform="translate(-370.339 -330.914)"
                            />
                            <path
                              style="fill: #444;"
                              d="M737.52,1011.545c22.553,7.081,43.829,7.838,63.656-12.7C794.047,1022.238,754.573,1028.661,737.52,1011.545Z"
                              transform="translate(-516.798 -743.698)"
                            />
                            <path
                              style="fill: #444;"
                              d="M830.585,1102.794c-11.75,9.128-19.974,10.475-29.835,2.553C809.935,1100.319,818.428,1102.806,830.585,1102.794Z"
                              transform="translate(-561.105 -816.11)"
                            />
                            <path
                              style="fill: #3f2030;"
                              d="M944.145,635.612c9.517,1.736,18.669,4.33,25.842,11.291,2.481,2.394,4.229,5.306,2.113,8.927-2.248,3.846-5.24-1.568-9.277-3.307-7.437-3.217-15.116.045-23.2-1.5-4.968-.94-7.6-4.295-6.488-9.325C934.434,635.911,938.776,634.555,944.145,635.612Z"
                              transform="translate(-653.695 -488.937)"
                            />
                            <path
                              style="fill: #402031;"
                              d="M651.074,644.617c-.637,1.02-1.416,4-3.113,4.684-9.664,3.864-18.211,4.378-28.317,6.309-5.985,1.143-10.5-.126-7.251-6.653,3.516-7.057,26.136-15.915,33.237-13.162C649.069,637.1,651.059,639.542,651.074,644.617Z"
                              transform="translate(-428.356 -488.935)"
                            />
                            <path
                              style="fill: #7a3633;"
                              d="M809.787,790.86c7.44,11.893.3,20.309.838,28.976.554,8.772-1.888,18.9,13.06,15.383-2.93,11.1-9.292,7.182-14.781,5.656-6.728-1.885-6.713-6.3-5.267-12.333,1.419-5.914.661-12.318,1.553-18.4C806.016,804.6,807.761,799.2,809.787,790.86Z"
                              transform="translate(-562.586 -597.954)"
                            />
                            <path
                              style="fill: #4c2833;"
                              d="M690.677,756.318c-.745,5.288-.509,12.713-6.5,12.839-7.183.153-7.1-7.545-7.329-13.126-.209-5.306.392-12.57,6.225-12.7C690.15,743.185,689.563,751.1,690.677,756.318Z"
                              transform="translate(-474.261 -564.65)"
                            />
                            <path
                              style="fill: #4e2934;"
                              d="M959,756.16c-1.721,5.387-.793,13.512-8.233,12.977-5.866-.425-5.956-7.649-5.836-12.911.129-5.686.368-13.168,7.506-12.8C958.355,743.758,957.667,751.392,959,756.16Z"
                              transform="translate(-662.12 -564.708)"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="link-socialmedia">
                  <a id="icon-behance" class="icon-socialmedia" href="https://www.behance.net/nbraveen" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      style="transform: scale(1.2);"
                      viewBox="0 0 95.475 91"
                    >
                      <defs>
                        <radialGradient
                          id="aa"
                          cx="0.5"
                          cy="0.5"
                          r="0.512"
                          gradientTransform="translate(0.023) scale(0.953 1)"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#c5f1ff" />
                          <stop offset="0.35" stop-color="#cdf3ff" />
                          <stop offset="0.907" stop-color="#e4faff" />
                          <stop offset="1" stop-color="#e9fbff" />
                        </radialGradient>
                        <linearGradient
                          id="bb"
                          x1="0.5"
                          y1="1.125"
                          x2="0.5"
                          y2="0.125"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#155cde" />
                          <stop offset="0.278" stop-color="#1f7fe5" />
                          <stop offset="0.569" stop-color="#279ceb" />
                          <stop offset="0.82" stop-color="#2cafef" />
                          <stop offset="1" stop-color="#2eb5f0" />
                        </linearGradient>
                      </defs>
                      <g transform="translate(0)">
                        <path
                          style="fill: url(#aa);"
                          d="M86.524,80.066a2.984,2.984,0,0,1,0-5.967h3.73a5.221,5.221,0,1,0,0-10.443H74.59a3.73,3.73,0,0,1,0-7.459H86.524a4.475,4.475,0,1,0,0-8.951H62.656V38.3H89.508a5.982,5.982,0,0,0,5.936-6.579,6.16,6.16,0,0,0-6.218-5.356h-2.7a2.984,2.984,0,0,1,0-5.967h.533a3.877,3.877,0,0,0,3.923-3.345,3.73,3.73,0,0,0-3.71-4.114H66.385a2.238,2.238,0,0,1,0-4.475h5.754a3.877,3.877,0,0,0,3.923-3.345A3.73,3.73,0,0,0,72.352,1H23.336a3.877,3.877,0,0,0-3.923,3.345,3.73,3.73,0,0,0,3.71,4.114h5.221a2.984,2.984,0,1,1,0,5.967H9.233a6.161,6.161,0,0,0-6.218,5.356,5.982,5.982,0,0,0,5.936,6.579h3.73a5.221,5.221,0,0,1,0,10.443H7.741a6.161,6.161,0,0,0-6.218,5.356,5.982,5.982,0,0,0,5.936,6.579H32.82v16.41H8.951a4.475,4.475,0,0,0,0,8.951h0a2.984,2.984,0,0,1,0,5.967h-2.7A6.161,6.161,0,0,0,.031,85.421,5.982,5.982,0,0,0,5.967,92H86.243a6.161,6.161,0,0,0,6.218-5.356A5.982,5.982,0,0,0,86.524,80.066Z"
                          transform="translate(0 -1)"
                        />
                        <path
                          style="fill: url(#bb);"
                          d="M69.672,12h-53.7A5.967,5.967,0,0,0,10,17.967v53.7a5.967,5.967,0,0,0,5.967,5.967h53.7a5.967,5.967,0,0,0,5.967-5.967v-53.7A5.967,5.967,0,0,0,69.672,12Z"
                          transform="translate(4.918 4.41)"
                        />
                        <path
                          style="fill: #fff"
                          d="M17,28.984V40.918H30.426a7.46,7.46,0,0,0,7.459-7.459h0A7.46,7.46,0,0,0,30.426,26H19.984A2.983,2.983,0,0,0,17,28.984Zm11.934,7.459H22.967V30.475h5.967a2.983,2.983,0,0,1,2.984,2.984h0A2.983,2.983,0,0,1,28.934,36.443Z"
                          transform="translate(8.36 11.295)"
                        />
                        <path
                          style="fill: #fff"
                          d="M17,36.443V49.869a2.983,2.983,0,0,0,2.984,2.984H31.172a8.2,8.2,0,0,0,8.2-8.2h0a8.2,8.2,0,0,0-8.2-8.2ZM29.68,48.377H22.967V40.918H29.68a3.729,3.729,0,0,1,3.73,3.729h0A3.729,3.729,0,0,1,29.68,48.377Zm26.852-17.9H47.582a2.239,2.239,0,0,1-2.238-2.238h0A2.239,2.239,0,0,1,47.582,26h8.951a2.239,2.239,0,0,1,2.238,2.238h0A2.239,2.239,0,0,1,56.533,30.475Zm5.147,11.5A9.683,9.683,0,0,0,52.5,33.469a9.99,9.99,0,0,0-10.137,9.512,9.7,9.7,0,0,0,9.7,9.871c4.5,0,7.38-1.842,9.009-4.587a.923.923,0,0,0-.8-1.38H56.239a.9.9,0,0,0-.685.292c-.641.746-1.508,1.2-3.5,1.2-1.88,0-3.416-1.954-3.67-4.475H60.262a1.479,1.479,0,0,0,1.462-1.268A4.691,4.691,0,0,0,61.68,41.977ZM48.517,40.918a4.025,4.025,0,0,1,3.54-2.984,4.025,4.025,0,0,1,3.54,2.984Z"
                          transform="translate(8.36 11.295)"
                        />
                      </g>
                    </svg>
                  </a>
                  <a id="icon-instagram" class="icon-socialmedia" href="https://www.instagram.com/n.braveen" target="_blank">
                    <svg
                      viewBox="0 0 512 512"
                      style="enable-background:new 0 0 512 512;"
                    >
                      <linearGradient
                        id="SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="40.7386"
                        y1="470.924"
                        x2="586.2218"
                        y2="-74.5592"
                      >
                        <stop offset="0.0011" style="stop-color:#FBE18A" />
                        <stop offset="0.2094" style="stop-color:#FCBB45" />
                        <stop offset="0.3765" style="stop-color:#F75274" />
                        <stop offset="0.5238" style="stop-color:#D53692" />
                        <stop offset="0.7394" style="stop-color:#8F39CE" />
                        <stop offset="1" style="stop-color:#5B4FE9" />
                      </linearGradient>
                      <circle
                        style="fill:url(#SVGID_1_);"
                        cx="255.9759"
                        cy="255.6866"
                        r="252.4099"
                      />
                      <g>
                        <circle
                          style="fill:#FFFFFF;"
                          cx="345.3287"
                          cy="167.778"
                          r="19.6088"
                        />
                        <path
                          style="fill:#FFFFFF;"
                          d="M258.1521,173.3155c-45.419,0-82.3722,36.952-82.3722,82.371s36.9532,82.3709,82.3722,82.3709
                        s82.3697-36.9519,82.3697-82.3709S303.5711,173.3155,258.1521,173.3155z M258.1521,308.451
                        c-29.0947,0-52.7658-23.6693-52.7658-52.7646c0-29.0947,23.6711-52.7639,52.7658-52.7639s52.7627,23.6692,52.7627,52.7639
                        C310.9148,284.7818,287.2469,308.451,258.1521,308.451z"
                        />
                        <g>
                          <path
                            style="fill:#FFFFFF;"
                            d="M323.5443,422.9243h-133.537c-55.3983,0-100.4662-45.0678-100.4662-100.4662V188.9154
                          c0-55.399,45.0678-100.4662,100.4662-100.4662h133.537c55.3983,0,100.4718,45.0672,100.4718,100.4662v133.5427
                          C424.0161,377.8564,378.9427,422.9243,323.5443,422.9243z M190.0073,119.9159c-38.0489,0-68.9995,30.9506-68.9995,68.9995
                          v133.5427c0,38.0489,30.9506,68.9995,68.9995,68.9995h133.537c38.0489,0,69.0052-30.9506,69.0052-68.9995V188.9154
                          c0-38.0489-30.9563-68.9995-69.0052-68.9995H190.0073z"
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a id="icon-linkedin" class="icon-socialmedia" href="https://www.linkedin.com/in/braveen" target="_blank">
                    <svg
                      viewBox="0 0 112.196 112.196"
                      style="enable-background:new 0 0 112.196 112.196;"
                    >
                      <g>
                        <circle
                          style="fill:#007AB9;"
                          cx="56.098"
                          cy="56.097"
                          r="56.098"
                        />
                        <g>
                          <path
                            style="fill:#F1F2F2;"
                            d="M89.616,60.611v23.128H76.207V62.161c0-5.418-1.936-9.118-6.791-9.118
                        c-3.705,0-5.906,2.491-6.878,4.903c-0.353,0.862-0.444,2.059-0.444,3.268v22.524H48.684c0,0,0.18-36.546,0-40.329h13.411v5.715
                        c-0.027,0.045-0.065,0.089-0.089,0.132h0.089v-0.132c1.782-2.742,4.96-6.662,12.085-6.662
                        C83.002,42.462,89.616,48.226,89.616,60.611L89.616,60.611z M34.656,23.969c-4.587,0-7.588,3.011-7.588,6.967
                        c0,3.872,2.914,6.97,7.412,6.97h0.087c4.677,0,7.585-3.098,7.585-6.97C42.063,26.98,39.244,23.969,34.656,23.969L34.656,23.969z
                        M27.865,83.739H41.27V43.409H27.865V83.739z"
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a id="icon-gitlab" class="icon-socialmedia" href="https://gitlab.com/braveen" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 69.049 64.254"
                    >
                      <defs>
                        <linearGradient
                          id="a"
                          x1="2.41"
                          y1="0.675"
                          x2="2.41"
                          y2="3.362"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#fff" />
                          <stop offset="1" stop-color="#ffe1ea" />
                        </linearGradient>
                        <linearGradient
                          id="b"
                          x1="6.449"
                          y1="0.675"
                          x2="6.449"
                          y2="3.362"
                          xlink:href="#a"
                        />
                        <linearGradient
                          id="c"
                          x1="3.32"
                          y1="1.575"
                          x2="3.32"
                          y2="4.262"
                          xlink:href="#a"
                        />
                        <linearGradient
                          id="d"
                          x1="1.576"
                          y1="0.227"
                          x2="1.576"
                          y2="2.927"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#ffe29f" />
                          <stop offset="1" stop-color="#ff719a" />
                        </linearGradient>
                        <linearGradient
                          id="e"
                          x1="3.147"
                          y1="0.227"
                          x2="3.147"
                          y2="2.927"
                          xlink:href="#d"
                        />
                        <linearGradient
                          id="f"
                          x1="1.61"
                          y1="-0.26"
                          x2="1.61"
                          y2="4.127"
                          xlink:href="#d"
                        />
                      </defs>
                      <path
                        style="fill: url(#b);"
                        d="M17.767,8,8.684,32.673H25.636Zm0,0"
                        transform="translate(-2.905 -6.766)"
                      />
                      <path
                        style="fill: url(#b);"
                        d="M49.443,8,41.574,32.673H58.527Zm0,0"
                        transform="translate(4.743 -6.766)"
                      />
                      <path
                        style="fill: url(#c);"
                        d="M22.426,28,34.237,65.009,46.048,28Zm0,0"
                        transform="translate(0.288 -1.989)"
                      />
                      <path
                        style="fill: url(#d);"
                        d="M38.542,71.254a1.258,1.258,0,0,1-.737-.241L4.5,46.3a1.234,1.234,0,0,1-.419-1.419L17.7,7.811A1.262,1.262,0,0,1,18.886,7a1.243,1.243,0,0,1,1.152.859l19.68,61.783a1.237,1.237,0,0,1-1.176,1.612ZM6.708,44.866l29.5,21.88L18.78,12.039Zm0,0"
                        transform="translate(-3.999 -7)"
                      />
                      <path
                        style="fill: url(#e);"
                        d="M32.234,71.254a1.237,1.237,0,0,1-1.176-1.612L50.738,7.859A1.243,1.243,0,0,1,51.89,7a1.346,1.346,0,0,1,1.185.806L66.7,44.876a1.238,1.238,0,0,1-.419,1.424L32.971,71.013A1.258,1.258,0,0,1,32.234,71.254ZM52,12.039,34.571,66.746l29.5-21.88Zm0,0"
                        transform="translate(2.273 -7)"
                      />
                      <path
                        style="fill: url(#f);"
                        d="M37.663,66.476A1.234,1.234,0,0,1,36.69,66L7.944,28.99A1.232,1.232,0,0,1,8.916,27h57.5a1.234,1.234,0,0,1,.973,1.99L38.636,66A1.234,1.234,0,0,1,37.663,66.476ZM11.435,29.467,37.663,63.233,63.891,29.467Zm0,0"
                        transform="translate(-3.138 -2.222)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 biography_info__">
          <section class="introduction">
            <h2 class="biography__title">Who is Braveen?</h2>
            <p class="biography__text">
              Hi! I am a full-stack developer and web designer located in
              Lohmar, Germany.
            </p>
            <p class="biography__text">
              Since 2016 I have been working as a freelance
              <b>Web Designer</b> & <b>Developer</b>. I worked on a multitude of
              projects ranging from web and print designs, development projects,
              building <b>eCommerce</b>
              platforms, marketing, logo design and branding.
            </p>
            <p class="biography__text">
              From <b>marketing</b> to running a <b>business</b>, design and
              development are basic services for the current world, and having
              them gives you the superpowers to face any problem in a very
              pragmatic way.
            </p>
            <p class="biography__text">
              I started to <b>design</b> and study <b>coding</b> on my own. I’ve
              tried to use each project as an opportunity to learn more and
              master my skills. Working on different projects and helping them
              develop has given me great learning <b>opportunities</b>, both
              interesting and practical, required to perform each day better
              than the last!
              <b><i>#LearnByDoing</i></b>
            </p>
            <p class="biography__text">
              Currently I work as Project Manager at Saily Group AG’s new
              technology team in Germany.
            </p>
          </section>
          <section class="skill">
            <h2 class="biography__sub_title">#Skills:</h2>
            <div class="row wrapper-skill">
              <div id="icon-adobe" class="icon-skill">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      style="fill:#F44336;"
                      d="M211.563,26.133c-1.971-2.993-5.313-4.796-8.896-4.8h-192C4.776,21.333,0,26.109,0,32v448
		c-0.001,5.07,3.566,9.439,8.533,10.453c0.702,0.141,1.417,0.213,2.133,0.213c4.255,0.015,8.111-2.5,9.813-6.4l192-448
		C213.909,32.951,213.564,29.138,211.563,26.133z"
                    />
                    <path
                      style="fill:#F44336;"
                      d="M265.877,187.989c-1.632-4.021-5.537-6.653-9.877-6.656l0,0c-4.293-0.001-8.169,2.571-9.835,6.528
		l-85.333,202.667c-2.286,5.43,0.263,11.684,5.692,13.97c1.311,0.552,2.72,0.836,4.142,0.836h77.931l29.419,78.421
		c1.564,4.158,5.541,6.912,9.984,6.912h85.333c5.891-0.004,10.664-4.782,10.66-10.673c-0.001-1.373-0.267-2.732-0.783-4.004
		L265.877,187.989z"
                    />
                    <path
                      style="fill:#F44336;"
                      d="M501.333,21.333h-192c-5.891-0.021-10.683,4.738-10.704,10.629c-0.005,1.481,0.298,2.947,0.89,4.304
		l192,448c1.702,3.9,5.559,6.415,9.813,6.4c0.716-0.001,1.431-0.072,2.133-0.213c4.967-1.014,8.534-5.384,8.533-10.453V32
		C512,26.109,507.224,21.333,501.333,21.333z"
                    />
                  </g>
                </svg>
              </div>
              <div id="icon-php" class="icon-skill">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;"
                  xml:space="preserve"
                >
                  <path
                    style="fill:#8F9ED1;"
                    d="M512,256c0,15.485-1.379,30.647-4.012,45.369C486.578,421.115,381.9,512,256,512
	c-94.856,0-177.664-51.587-221.884-128.24c-10.794-18.693-19.278-38.87-25.088-60.155C3.135,302.07,0,279.395,0,256
	C0,114.615,114.615,0,256,0c116.694,0,215.144,78.075,245.979,184.842C508.5,207.433,512,231.309,512,256z"
                  />
                  <g>
                    <path
                      style="fill:#F2F2F2;"
                      d="M130.173,178.239H35.892L9.028,323.605c5.81,21.285,14.294,41.462,25.088,60.155h8.746
		l10.407-56.299h51.806c63.08,0,80.039-56.633,84.104-84.449C193.254,215.207,172.91,178.239,130.173,178.239z M143.851,247.703
		c-2.309,15.768-13.96,47.877-49.716,47.877H59.162l15.632-84.605h35.6C145.095,210.975,146.16,231.936,143.851,247.703z"
                    />
                    <path
                      style="fill:#F2F2F2;"
                      d="M501.979,184.842c-8.014-4.138-17.565-6.604-28.599-6.604h-94.281L341.117,383.76h44.951
		l10.407-56.299h51.806c28.056,0,46.989-11.201,59.705-26.091C510.621,286.647,512,271.485,512,256
		C512,231.309,508.5,207.433,501.979,184.842z M487.058,247.703c-2.309,15.768-13.96,47.877-49.727,47.877h-34.962l15.632-84.605
		h35.6C488.302,210.975,489.367,231.936,487.058,247.703z"
                    />
                    <path
                      style="fill:#F2F2F2;"
                      d="M309.238,178.919c-18.295,0-42.704,0-54.597,0l10.248-55.451h-44.766L182.14,328.984h44.766
		l21.843-118.186c8.07,0,18.79,0,28.61,0c18.991,0,31.879,4.07,29.165,21.705c-2.713,17.635-18.313,95.636-18.313,95.636h45.444
		c0,0,17.635-86.818,20.348-111.237C356.717,192.484,334.334,178.919,309.238,178.919z"
                    />
                  </g>
                </svg>
              </div>
              <div id="icon-python" class="icon-skill">
                <svg viewBox="0 0 256 255">
                  <defs>
                    <linearGradient
                      x1="12.9593594%"
                      y1="12.0393928%"
                      x2="79.6388325%"
                      y2="78.2008538%"
                      id="linearGradient-1"
                    >
                      <stop stop-color="#387EB8" offset="0%"></stop>
                      <stop stop-color="#366994" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient
                      x1="19.127525%"
                      y1="20.5791813%"
                      x2="90.7415328%"
                      y2="88.4290372%"
                      id="linearGradient-2"
                    >
                      <stop stop-color="#FFE052" offset="0%"></stop>
                      <stop stop-color="#FFC331" offset="100%"></stop>
                    </linearGradient>
                  </defs>
                  <g>
                    <path
                      d="M126.915866,0.0722755491 C62.0835831,0.0722801733 66.1321288,28.1874648 66.1321288,28.1874648 L66.2044043,57.3145115 L128.072276,57.3145115 L128.072276,66.0598532 L41.6307171,66.0598532 C41.6307171,66.0598532 0.144551098,61.3549438 0.144551098,126.771315 C0.144546474,192.187673 36.3546019,189.867871 36.3546019,189.867871 L57.9649915,189.867871 L57.9649915,159.51214 C57.9649915,159.51214 56.8001363,123.302089 93.5968379,123.302089 L154.95878,123.302089 C154.95878,123.302089 189.434218,123.859386 189.434218,89.9830604 L189.434218,33.9695088 C189.434218,33.9695041 194.668541,0.0722755491 126.915866,0.0722755491 L126.915866,0.0722755491 L126.915866,0.0722755491 Z M92.8018069,19.6589497 C98.9572068,19.6589452 103.932242,24.6339846 103.932242,30.7893845 C103.932246,36.9447844 98.9572068,41.9198193 92.8018069,41.9198193 C86.646407,41.9198239 81.6713721,36.9447844 81.6713721,30.7893845 C81.6713674,24.6339846 86.646407,19.6589497 92.8018069,19.6589497 L92.8018069,19.6589497 L92.8018069,19.6589497 Z"
                      fill="url(#linearGradient-1)"
                    ></path>
                    <path
                      d="M128.757101,254.126271 C193.589403,254.126271 189.540839,226.011081 189.540839,226.011081 L189.468564,196.884035 L127.600692,196.884035 L127.600692,188.138693 L214.042251,188.138693 C214.042251,188.138693 255.528417,192.843589 255.528417,127.427208 C255.52844,62.0108566 219.318366,64.3306589 219.318366,64.3306589 L197.707976,64.3306589 L197.707976,94.6863832 C197.707976,94.6863832 198.87285,130.896434 162.07613,130.896434 L100.714182,130.896434 C100.714182,130.896434 66.238745,130.339138 66.238745,164.215486 L66.238745,220.229038 C66.238745,220.229038 61.0044225,254.126271 128.757101,254.126271 L128.757101,254.126271 L128.757101,254.126271 Z M162.87116,234.539597 C156.715759,234.539597 151.740726,229.564564 151.740726,223.409162 C151.740726,217.253759 156.715759,212.278727 162.87116,212.278727 C169.026563,212.278727 174.001595,217.253759 174.001595,223.409162 C174.001618,229.564564 169.026563,234.539597 162.87116,234.539597 L162.87116,234.539597 L162.87116,234.539597 Z"
                      fill="url(#linearGradient-2)"
                    ></path>
                  </g>
                </svg>
              </div>
              <div id="icon-js" class="icon-skill">
                <svg viewBox="0 0 512 512">
                  <rect width="512" height="512" rx="15%" fill="#f7df1e" />
                  <path
                    d="m324,370c10,17 24,29 47,29 20,0 33,-10 33,-24 0,-16 -13,-22 -35,-32l-12,-5c-35,-15 -58,-33 -58,-72 0,-36 27,-64 70,-64 31,0 53,11 68,39l-37,24c-8,-15 -17,-21 -31,-21 -14,0 -23,9 -23,21 0,14 9,20 30,29l12,5c41,18 64,35 64,76 0,43 -34,67 -80,67 -45,0 -74,-21 -88,-49zm-170,4c8,13 14,25 31,25 16,0 26,-6 26,-30V203h48v164c0,50 -29,72 -72,72 -39,0 -61,-20 -72,-44z"
                  />
                </svg>
              </div>
              <div id="icon-nodejs" class="icon-skill">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;"
                  xml:space="preserve"
                >
                  <path
                    style="fill:#F2F2F2;"
                    d="M512,256c0,141.385-114.615,256-256,256C125.555,512,17.899,414.438,2.027,288.298
	C0.69,277.723,0,266.94,0,256c0-20.041,2.299-39.549,6.656-58.264C33.029,84.428,134.656,0,256,0
	c121.041,0,222.469,84.01,249.135,196.901c3.5,14.764,5.705,30.02,6.51,45.641C511.885,247.003,512,251.486,512,256z"
                  />
                  <polygon
                    style="fill:#3B8739;"
                    points="243.195,276.559 243.195,210 185.553,176.721 127.912,210 127.912,276.559 185.553,309.838"
                  />
                  <path
                    style="fill:#333333;"
                    d="M470.204,266.459l37.564,21.692l-37.564,21.692l-57.637-33.28v-66.56l57.637-33.28l34.931,20.177
	c3.5,14.764,5.705,30.02,6.51,45.641l-21.358,12.33v-23.186l-20.083-11.588l-20.083,11.588v23.186L470.204,266.459z"
                  />
                  <polygon
                    style="fill:#66A060;"
                    points="481.392,249.74 481.392,236.82 470.203,230.36 459.013,236.82 459.013,249.74 470.203,256.201 "
                  />
                  <path
                    style="fill:#333333;"
                    d="M100.686,210.004v87.771l-38.442-21.932v-44.993L43.05,219.763l-19.195,11.086v44.993L2.027,288.298
	C0.69,277.723,0,266.94,0,256c0-20.041,2.299-39.549,6.656-58.264l36.394-21.013L100.686,210.004z"
                  />
                  <rect
                    x="127.906"
                    y="210.001"
                    transform="matrix(0.5 0.866 -0.866 0.5 303.4564 -39.0495)"
                    style="fill:#6DA75D;"
                    width="115.281"
                    height="66.557"
                  />
                  <polygon
                    style="fill:#55A344;"
                    points="127.912,276.559 147.704,244.283 127.912,210 "
                  />
                  <polygon
                    style="fill:#639D57;"
                    points="223.979,243.277 243.195,210 185.553,176.721 "
                  />
                  <path
                    style="fill:#333333;"
                    d="M346.551,99.579v88.304l-19.32-11.16l-57.647,33.28v66.56l57.647,33.28l57.637-33.28V120.09
	L346.551,99.579z M346.446,254.37l-19.216,11.097l-19.216-11.097v-22.183l19.216-11.097l19.216,11.097V254.37z"
                  />
                  <g>
                    <path
                      style="fill:#66A060;"
                      d="M282.149,358.717c0,0-1.132-9.729-11.539-9.729c-10.408,0-17.534,1.357-17.534,7.127
		s20.25,5.77,27.943,8.032c7.693,2.262,14.48,8.145,11.086,18.553c-3.394,10.408-13.802,11.539-29.866,11.539
		s-19.685-13.35-19.685-18.553h9.05c0,0-1.132,8.145,9.502,9.502c10.634,1.357,20.802,2.121,22.173-6.108
		c1.81-10.861-34.391-5.43-37.559-16.064c-2.676-8.985-1.132-20.815,16.969-22.626c18.101-1.811,28.056,6.181,28.056,18.101
		L282.149,358.717z"
                    />
                    <path
                      style="fill:#66A060;"
                      d="M194.723,401.131l20.008,11.551c10.053,5.804,22.619-1.451,22.619-13.059v-20.242v-37.253h-8.155
		v55.227c0,6.339-6.862,10.302-12.353,7.131l-13.965-8.062v-58.94l51.043-29.467l51.043,29.467v58.94l-51.043,29.467l-14.704-8.489
		l-9.602,3.872l24.305,14.033l59.198-34.179v-68.351L253.92,298.6l-59.198,34.179v68.352H194.723z"
                    />
                  </g>
                </svg>
              </div>
              <div id="icon-angular" class="icon-skill">
                <svg viewBox="0 0 256 272">
                  <g>
                    <path
                      d="M0.0996108949,45.522179 L125.908171,0.697276265 L255.103502,44.7252918 L234.185214,211.175097 L125.908171,271.140856 L19.3245136,211.971984 L0.0996108949,45.522179 Z"
                      fill="#E23237"
                    ></path>
                    <path
                      d="M255.103502,44.7252918 L125.908171,0.697276265 L125.908171,271.140856 L234.185214,211.274708 L255.103502,44.7252918 L255.103502,44.7252918 Z"
                      fill="#B52E31"
                    ></path>
                    <path
                      d="M126.107393,32.27393 L126.107393,32.27393 L47.7136187,206.692607 L76.9992218,206.194553 L92.7377432,166.848249 L126.207004,166.848249 L126.306615,166.848249 L163.063035,166.848249 L180.29572,206.692607 L208.286381,207.190661 L126.107393,32.27393 L126.107393,32.27393 Z M126.306615,88.155642 L152.803113,143.5393 L127.402335,143.5393 L126.107393,143.5393 L102.997665,143.5393 L126.306615,88.155642 L126.306615,88.155642 Z"
                      fill="#FFFFFF"
                    ></path>
                  </g>
                </svg>
              </div>
              <div id="icon-wordpress" class="icon-skill">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 447.674 447.674"
                  style="enable-background:new 0 0 447.674 447.674;"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      style="fill:#00769D;"
                      d="M134.289,138.16h-24.722l67.399,190.521l37.732-107.825l-29.254-82.696H159.36v-18.154h115.508
		v18.154h-27.049l67.398,190.521l24.227-69.234c31.781-88.702-26.048-116.333-26.048-136.129s16.048-35.843,35.843-35.843
		c1.071,0,2.111,0.058,3.13,0.153c-33.541-31.663-78.768-51.08-128.534-51.08c-65.027,0-122.306,33.146-155.884,83.458h66.336
		v18.154L134.289,138.16L134.289,138.16z"
                    />
                    <path
                      style="fill:#00769D;"
                      d="M36.548,223.837c0,71.704,40.302,133.986,99.483,165.458l-84.52-238.919
		C41.883,172.932,36.548,197.761,36.548,223.837z"
                    />
                    <path
                      style="fill:#00769D;"
                      d="M386.833,131.547c2.679,15.774,1.868,33.503-2.243,51.301h0.745l-2.832,8.092l0,0
		c-1.678,5.843-3.791,11.82-6.191,17.693l-64.444,180.541c59.057-31.51,99.256-93.725,99.256-165.338
		C411.124,190.279,402.29,158.788,386.833,131.547z"
                    />
                    <path
                      style="fill:#00769D;"
                      d="M166.075,402.033c18.195,5.894,37.603,9.091,57.762,9.091c19.228,0,37.777-2.902,55.239-8.285
		l-54.784-154.862L166.075,402.033z"
                    />
                    <path
                      style="fill:#00769D;"
                      d="M382.113,65.56C339.836,23.283,283.625,0,223.836,0S107.837,23.283,65.56,65.56S0,164.047,0,223.837
		c0,59.789,23.283,115.999,65.56,158.276s98.488,65.56,158.277,65.56s115.999-23.283,158.277-65.56
		c42.277-42.277,65.56-98.488,65.56-158.276C447.673,164.047,424.39,107.837,382.113,65.56z M223.836,431.883
		c-114.717,0-208.046-93.329-208.046-208.046S109.119,15.79,223.836,15.79s208.046,93.33,208.046,208.047
		S338.554,431.883,223.836,431.883z"
                    />
                  </g>
                </svg>
              </div>
              <div id="icon-woocommerce" class="icon-skill">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                  <path
                    fill="#a64a7b"
                    d="M43,11H5c-2.209,0-4,1.791-4,4v16c0,2.209,1.791,4,4,4h19l8,4l-2-4h13c2.209,0,4-1.791,4-4V15 C47,12.791,45.209,11,43,11z"
                  />
                  <path
                    fill="#fff"
                    d="M40.443 19c.041 0 .132.005.277.038.342.077.559.198.82.686C41.85 20.283 42 21.007 42 21.939c0 1.398-.317 2.639-.973 3.802C40.321 27 39.805 27 39.557 27c-.041 0-.132-.005-.277-.038-.342-.077-.559-.198-.809-.666C38.158 25.722 38 24.963 38 24.043c0-1.399.314-2.63.963-3.765C39.691 19 40.218 19 40.443 19M40.443 16c-1.67 0-3.026.931-4.087 2.793C35.452 20.375 35 22.125 35 24.043c0 1.434.278 2.662.835 3.686.626 1.173 1.548 1.88 2.783 2.16C38.948 29.963 39.261 30 39.557 30c1.687 0 3.043-.931 4.087-2.793C44.548 25.606 45 23.856 45 21.939c0-1.452-.278-2.662-.835-3.668-.626-1.173-1.548-1.88-2.783-2.16C41.052 16.037 40.739 16 40.443 16L40.443 16zM28.443 19c.041 0 .132.005.268.036.333.076.571.207.829.689C29.85 20.283 30 21.007 30 21.939c0 1.398-.317 2.639-.973 3.802C28.321 27 27.805 27 27.557 27c-.041 0-.132-.005-.277-.038-.342-.077-.559-.198-.809-.666C26.158 25.722 26 24.963 26 24.043c0-1.399.314-2.63.963-3.765C27.691 19 28.218 19 28.443 19M28.443 16c-1.67 0-3.026.931-4.087 2.793C23.452 20.375 23 22.125 23 24.043c0 1.434.278 2.662.835 3.686.626 1.173 1.548 1.88 2.783 2.16C26.948 29.963 27.261 30 27.557 30c1.687 0 3.043-.931 4.087-2.793C32.548 25.606 33 23.856 33 21.939c0-1.452-.278-2.662-.835-3.668-.626-1.173-1.565-1.88-2.783-2.16C29.052 16.037 28.739 16 28.443 16L28.443 16zM18.5 32c-.421 0-.832-.178-1.123-.505-2.196-2.479-3.545-5.735-4.34-8.343-1.144 2.42-2.688 5.515-4.251 8.119-.309.515-.894.792-1.491.715-.596-.083-1.085-.513-1.242-1.093-2.212-8.127-3.007-13.95-3.039-14.194-.11-.82.466-1.575 1.286-1.686.831-.108 1.576.465 1.687 1.286.007.049.571 4.177 2.033 10.199 2.218-4.208 4.078-8.535 4.102-8.59.267-.62.919-.989 1.58-.895.668.09 1.194.615 1.285 1.283.007.052.542 3.825 2.245 7.451.719-7.166 2.873-10.839 2.982-11.021.427-.711 1.35-.941 2.058-.515.711.426.941 1.348.515 2.058C22.762 16.313 20 21.115 20 30.5c0 .623-.386 1.182-.968 1.402C18.858 31.968 18.679 32 18.5 32z"
                  />
                </svg>
              </div>
              <div id="icon-arduino" class="icon-skill">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1372.201 1372.684"
                >
                  <path
                    fill="#00979D"
                    stroke="#81C9CB"
                    stroke-width=".932"
                    stroke-miterlimit="10"
                    d="M1371.701 686.024c0 378.658-306.972 685.605-685.549 685.605C307.451 1371.629.5 1064.682.5 686.024.5 307.455 307.451.483 686.152.483c378.594.001 685.549 306.972 685.549 685.541z"
                  />
                  <linearGradient
                    id="a"
                    gradientUnits="userSpaceOnUse"
                    x1="-16.3"
                    y1="16.071"
                    x2="1354.901"
                    y2="16.071"
                    gradientTransform="matrix(1 0 0 -1 16.8 702.696)"
                  >
                    <stop offset=".117" stop-color="#fff" stop-opacity="0" />
                    <stop
                      offset=".252"
                      stop-color="#c0d1d3"
                      stop-opacity=".153"
                    />
                    <stop
                      offset=".387"
                      stop-color="#91b3b7"
                      stop-opacity=".306"
                    />
                    <stop
                      offset=".52"
                      stop-color="#6d9fa3"
                      stop-opacity=".457"
                    />
                    <stop
                      offset=".65"
                      stop-color="#4d9195"
                      stop-opacity=".604"
                    />
                    <stop
                      offset=".776"
                      stop-color="#30888b"
                      stop-opacity=".746"
                    />
                    <stop
                      offset=".895"
                      stop-color="#148386"
                      stop-opacity=".881"
                    />
                    <stop offset="1" stop-color="#008184" />
                  </linearGradient>
                  <linearGradient
                    id="b"
                    gradientUnits="userSpaceOnUse"
                    x1="-16.8"
                    y1="16.071"
                    x2="1355.401"
                    y2="16.071"
                    gradientTransform="matrix(1 0 0 -1 16.8 702.696)"
                  >
                    <stop offset="0" stop-color="#fff" stop-opacity="0" />
                    <stop
                      offset=".153"
                      stop-color="#c0d1d3"
                      stop-opacity=".153"
                    />
                    <stop
                      offset=".306"
                      stop-color="#91b3b7"
                      stop-opacity=".306"
                    />
                    <stop
                      offset=".457"
                      stop-color="#6d9fa3"
                      stop-opacity=".457"
                    />
                    <stop
                      offset=".604"
                      stop-color="#4d9195"
                      stop-opacity=".604"
                    />
                    <stop
                      offset=".746"
                      stop-color="#30888b"
                      stop-opacity=".746"
                    />
                    <stop
                      offset=".881"
                      stop-color="#148386"
                      stop-opacity=".881"
                    />
                    <stop offset="1" stop-color="#008184" />
                  </linearGradient>
                  <path
                    opacity=".5"
                    fill="#00979D"
                    stroke="url(#b)"
                    stroke-miterlimit="10"
                    d="M1371.701 686.595c0 378.65-306.972 685.606-685.549 685.606C307.451 1372.201.5 1065.23.5 686.595.5 308.019 307.451 1.048 686.152 1.048c378.594.016 685.549 306.97 685.549 685.547z"
                  />
                  <g fill="#FFF">
                    <path
                      d="M947.959 931.196c-12.909 0-26.127-.929-39.127-2.864-108.978-15.554-181.848-93.822-222.665-153.989-40.946 60.166-113.811 138.512-222.74 154.045a275.864 275.864 0 0 1-39.133 2.785c-67.753 0-131.358-25.217-179.201-71.003-48.299-46.165-74.951-108.114-74.951-174.171 0-66.14 26.651-128.004 75.021-174.253 47.797-45.793 111.449-70.936 179.231-70.936 12.918 0 26.067.928 39.023 2.783 108.932 15.535 181.794 93.813 222.743 153.99 40.825-60.177 113.689-138.432 222.658-153.99 13-1.863 26.148-2.783 39.066-2.783 67.753 0 131.401 25.208 179.197 70.936 48.345 46.249 74.937 108.113 74.937 174.253 0 66.057-26.524 128.006-74.868 174.171-47.881 45.785-111.434 71.026-179.191 71.026M734.42 686.024c21.283 40.534 84.067 141.676 186.692 156.375 8.984 1.236 18.028 1.923 26.839 1.923 92.185 0 167.225-71.002 167.225-158.322s-75.023-158.321-167.291-158.321c-8.812 0-17.853.629-26.753 1.921-102.644 14.664-165.428 115.806-186.712 156.424M424.393 527.702c-92.308 0-167.36 70.998-167.36 158.321 0 87.305 75.021 158.322 167.245 158.322 8.852 0 17.897-.688 26.879-1.922 102.629-14.697 165.394-115.783 186.689-156.375-21.237-40.535-84.061-141.761-186.689-156.376-8.877-1.341-17.945-1.97-26.764-1.97"
                    />
                    <path
                      d="M354.37 662.051h152.625v49.181H354.37zM1016.484 662.051h-51.671v-51.747h-49.348v51.747h-51.648v49.181h51.648v51.737h49.348v-51.737h51.671z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </section>
          <section class="hobby">
            <h2 class="biography__sub_title">#Hobby:</h2>
            <p class="biography__text">
              One of my passions, other than reading books and watching movies,
              is taking photos. Here are some of my favourite shots!
            </p>
            <div class="favorite_books__img">
              <div>
                <img
                  class="img__fav-book"
                  src="../assets/img/IMG_1.jpg"
                  alt=""
                />
              </div>
              <div class="midle__fav_book_img">
                <img
                  class="img__fav-book"
                  src="../assets/img/IMG_2.jpg"
                  alt=""
                />
              </div>
              <div>
                <img
                  class="img__fav-book"
                  src="../assets/img/IMG_3.jpg"
                  alt=""
                />
              </div>
            </div>
          </section>
          <section class="favorite-books">
            <h2 class="biography__sub_title">#Some of my favourite Books</h2>
            <ul
              class="biography__text"
              style="list-style-type: square;padding-left: 22px;"
            >
              <li>The Lean startup - Eric Ries</li>
              <li>Learning Politics From Sivaram - Mark P. Whitaker</li>
              <li>Half of a Yellow Sun - Chimamanda Ngozi Adichie</li>
            </ul>
            <img
              class="img__books"
              src="../assets/img/fav-books_b.jpg"
              alt="Braveen's favorite books"
            />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import Background from "@/components/shared/background.vue";

export default {
  name: "BraveenBiography",
  components: {
    Navbar,
    Background,
  },
  mounted() {
    this.$destroy();
    new this.$parallax(document.querySelector(".--biography_sketch1__"));
    new this.$parallax(document.querySelector(".--biography_b"));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.biography_b__ {
  padding-top: 10em;
  padding-left: 4em;
  padding-right: 4em;
}
.biography_info__ {
  overflow: auto;
  height: 100vh;
  padding-bottom: 8em;
}
.biography_profile__ {
  transform: scale(0.9) translate(0px, -10px);
}
.skill {
  padding-top: 4em;
}
.img__books {
  width: 280px;
  transform: translateX(3px);
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
}
.favorite-books {
  padding-bottom: 5em;
}
.favorite-movies {
  padding-top: 4em;
}
.hobby {
  padding-top: 4em;
}
.wrapper-skill {
  padding-left: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 0px;
  grid-row-gap: 10px;
}
.icon-skill {
  padding-right: 10px;
  height: 80px;
  width: 80px;
  line-height: 1;
  transition: all 0.3s ease;
}
.icon-socialmedia {
  height: 80px;
  width: 80px;
  padding: 5px;
  line-height: 1;
  transition: all 0.3s ease;
}
.favorite_books__img {
  display: inline-flex;
  padding-top: 1em;
  padding-bottom: 3em;
}
.midle__fav_book_img {
  padding-left: 1.3em;
  padding-right: 1.3em;
}
.biography_sketch1__ {
  position: absolute;
}
.biography__title {
  color: #212121;
  font-size: 48px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700;
}
.biography__sub_title {
  color: #212121;
  font: normal normal bold 32px/39px Montserrat;
  letter-spacing: 0px;
}
.biography__text {
  color: #212121;
  font-size: 32px;
  font-family: Montserrat-Regular, Montserrat;
}
.biography__text b {
  color: rgb(198, 67, 66);
}
.img__fav-book {
  width: 240px;
  height: 330px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
  object-fit: cover;
}
.link-socialmedia {
  display: flex;
  position: absolute;
  transform: translate(135px, 100px);
}
@media only screen and (max-height: 830px) and (min-width: 1450px) {
  .biography_profile__ {
    transform: scale(0.8) translate(-47px, -65px) !important;
  }
}
@media only screen and (max-width: 1855px) {
  .biography__title {
    font-size: 40px;
  }
  .biography__sub_title {
    font: normal normal bold 30px/35px Montserrat;
  }
  .biography__text {
    font-size: 28px;
  }
  .biography_info__ {
    padding-left: 5em;
  }
  .biography_profile__ {
    transform: scale(0.9) translate(-20px, -30px);
  }
}

@media only screen and (max-width: 1485px) {
  .biography__title {
    font-size: 35px;
  }
  .biography__sub_title {
    font: normal normal bold 25px/30px Montserrat;
  }
  .biography__text {
    font-size: 23px;
  }
  .biography_info__ {
    padding-left: 6em;
  }
  .biography_profile__ {
    transform: scale(0.75) translate(-60px, -85px);
  }
  .icon-skill {
    height: 70px;
    width: 70px;
  }
  .icon-socialmedia {
    height: 65px;
    width: 65px;
  }
  .img__fav-book {
    width: 190px;
    height: 250px;
  }
  .link-socialmedia {
    transform: scale(1.3) translate(130px, 90px);
  }
}
@media only screen and (max-width: 1310px) {
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 1215px) {
  .skill {
    padding-top: 3em;
  }
  .favorite-movies {
    padding-top: 3em;
  }
  .hobby {
    padding-top: 3em;
  }
  .biography__title {
    font-size: 32px;
  }
  .biography__sub_title {
    font: normal normal bold 22px/27px Montserrat;
  }
  .biography__text {
    font-size: 20px;
  }
  .biography_info__ {
    padding-left: 4em;
  }
  .biography_profile__ {
    transform: scale(0.7) translate(-110px, -115px);
  }
  .icon-skill {
    height: 65px;
    width: 65px;
  }
  .img__fav-book {
    width: 170px;
    height: 230px;
  }
  .link-socialmedia {
    transform: scale(1.3) translate(120px, 90px);
  }
}

@media only screen and (max-width: 1070px) {
  .skill {
    padding-top: 2em;
  }
  .favorite-movies {
    padding-top: 2em;
  }
  .hobby {
    padding-top: 2em;
  }
  .biography__title {
    font-size: 28px;
  }
  .biography__sub_title {
    font: normal normal bold 18px/23px Montserrat;
  }
  .biography__text {
    font-size: 17px;
  }
  .biography_profile__ {
    transform: scale(0.55) translate(-160px, -220px);
  }
  .icon-skill {
    height: 55px;
    width: 55px;
  }
  .icon-socialmedia {
    height: 65px;
    width: 65px;
  }
  .img__fav-book {
    width: 140px;
    height: 190px;
  }
}
@media only screen and (max-width: 930px) {
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 900px) {
  .img__fav-book {
    width: 125px;
    height: 165px;
  }
}
@media only screen and (max-width: 845px) {
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 830px) {
  .img__fav-book {
    width: 110px;
    height: 150px;
  }
}
/*responsive mobile*/
@media only screen and (max-width: 767px) {
  .biography_b__ {
    padding-top: 6.5em;
    padding-left: 4em;
    padding-right: 4em;
  }
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .icon-skill {
    height: 60px;
    width: 60px;
  }
  .biography__title {
    font-size: 30px;
  }
  .biography__sub_title {
    font: normal normal bold 20px/25px Montserrat;
  }
  .biography__text {
    font-size: 18px;
  }
  .biography_info__ {
    padding-left: 0em;
    height: 100%;
    padding-bottom: 0em;
  }
  .img__fav-book {
    width: 170px;
    height: 240px;
  }
  .biography_profile__ {
    transform: scale(0.7) translate(0px, -100px);
  }
  #biography_braveen__ {
    overflow: auto;
    height: 100vh;
  }
}
@media only screen and (max-width: 700px) {
  .biography_profile__ {
    transform: scale(0.7) translate(-40px, -100px);
  }
  .img__fav-book {
    width: 150px;
    height: 210px;
  }
}
@media only screen and (max-width: 630px) {
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 650px) {
  .biography_profile__ {
    transform: scale(0.7) translate(-60px, -100px);
  }
}
@media only screen and (max-width: 600px) {
  .biography_profile__ {
    transform: scale(0.7) translate(-70px, -100px);
  }
  .img__fav-book {
    width: 118px;
    height: 165px;
  }
}
@media only screen and (max-width: 580px) {
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 550px) {
  .biography_profile__ {
    transform: scale(0.7) translate(-80px, -100px);
  }
}
@media only screen and (max-width: 530px) {
  .biography_profile__ {
    transform: scale(0.7) translate(-100px, -100px);
  }
}
@media only screen and (max-width: 518px) {
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 499px) {
  .biography_profile__ {
    transform: scale(0.5) translate(-100px, -40px);
    height: 470px;
  }
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .icon-skill {
    height: 45px;
    width: 45px;
  }
  .biography_b__ {
    padding-top: 0em;
    padding-left: 3em;
    padding-right: 3em;
  }
  .favorite_books__img {
    display: contents;
  }
  .img__fav-book {
    width: 250px;
    height: auto;
  }
  .img__books {
    width: 250px;
    transform: unset;
  }
  .midle__fav_book_img {
    padding-left: unset;
    padding-right: unset;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }
  .favorite-books {
    padding-top: 2.5em;
  }
  .biography__title {
    font-size: 28px;
  }
  .biography__sub_title {
    font: normal normal bold 18px/23px Montserrat;
  }
  .biography__text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 465px) {
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 460px) {
  .biography_profile__ {
    transform: scale(0.5) translate(-120px, -40px);
  }
  .biography_b__ {
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media only screen and (max-width: 420px) {
  .biography_profile__ {
    transform: scale(0.5) translate(-130px, -40px);
  }
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 380px) {
  .biography_profile__ {
    transform: scale(0.5) translate(-140px, -40px);
  }
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 390px) {
  .biography_b__ {
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media only screen and (max-width: 370px) {
  .biography_profile__ {
    transform: scale(0.5) translate(-150px, -40px);
  }
  .biography_b__ {
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media only screen and (max-width: 330px) {
  .biography_profile__ {
    transform: scale(0.5) translate(-170px, -40px);
  }
  .biography_b__ {
    padding-left: 3em;
    padding-right: 3em;
  }
  .wrapper-skill {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
